// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktualnosci-index-js": () => import("./../../../src/pages/aktualnosci/index.js" /* webpackChunkName: "component---src-pages-aktualnosci-index-js" */),
  "component---src-pages-aktualnosci-mdx-slug-js": () => import("./../../../src/pages/aktualnosci/{mdx.slug}.js" /* webpackChunkName: "component---src-pages-aktualnosci-mdx-slug-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-partnerzy-js": () => import("./../../../src/pages/partnerzy.js" /* webpackChunkName: "component---src-pages-partnerzy-js" */),
  "component---src-pages-uslugi-doradztwo-biznesowe-js": () => import("./../../../src/pages/uslugi/doradztwo-biznesowe.js" /* webpackChunkName: "component---src-pages-uslugi-doradztwo-biznesowe-js" */),
  "component---src-pages-uslugi-finansowanie-js": () => import("./../../../src/pages/uslugi/finansowanie.js" /* webpackChunkName: "component---src-pages-uslugi-finansowanie-js" */),
  "component---src-pages-uslugi-ksiegowosc-i-kadry-js": () => import("./../../../src/pages/uslugi/ksiegowosc-i-kadry.js" /* webpackChunkName: "component---src-pages-uslugi-ksiegowosc-i-kadry-js" */),
  "component---src-pages-uslugi-nieruchomosci-js": () => import("./../../../src/pages/uslugi/nieruchomosci.js" /* webpackChunkName: "component---src-pages-uslugi-nieruchomosci-js" */),
  "component---src-pages-uslugi-projekty-granty-dotacje-js": () => import("./../../../src/pages/uslugi/projekty-granty-dotacje.js" /* webpackChunkName: "component---src-pages-uslugi-projekty-granty-dotacje-js" */)
}

